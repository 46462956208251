'use strict';

/**
 * Name: Navigation
 * Author: Graffino (http://www.graffino.com)
 */

$.extend($graffino, {
  navigation: {
    name: 'navigation',

    // Plugin options
    options: {
      autoInit: true,
      debug: true
    },

    // Scoped variables
    vars: {
      $trigger: $('.js-navigation-trigger'),
      $nav: $('.js-navigation-container'),
      thisScrollPosition: undefined
    },

    // Init method
    init: function init() {
      var _that = $graffino,
          _this = this,
          vars = this.vars;

      _this.log('Initialized.');

      // Check if element is in DOM
      if (_that.isOnPage(vars.$trigger) && _that.isOnPage(vars.$nav)) {
        vars.$trigger.on('click', function (e) {
          e.preventDefault();

          if (vars.$nav.hasClass(_that.vars.stateClass.expanded)) {
            _this.handleNavigation('close');
          } else {
            _this.handleNavigation('open');
          }
        });

        _that.vars.$document.on('keyup', function (e) {
          if (e.keyCode === 27) {
            _this.handleNavigation('close');
          }
        });
      } else {
        _this.log('\t\u2514 Element(s) not found in DOM.');
      }
    },

    handleNavigation: function handleNavigation(payload) {
      var _that = $graffino,
          _this = this,
          vars = this.vars;

      switch (payload) {
        case 'open':
          _this.handleWindowPosition('store');
          vars.$trigger.addClass(_that.vars.stateClass.active);
          vars.$nav.addClass(_that.vars.stateClass.expanded);
          _that.vars.$html.addClass(_that.vars.stateClass.fixed);
          break;
        case 'close':
          vars.$trigger.removeClass(_that.vars.stateClass.active);
          vars.$nav.removeClass(_that.vars.stateClass.expanded);
          _that.vars.$html.removeClass(_that.vars.stateClass.fixed);
          _this.handleWindowPosition('restore');
          break;
        default:
          _this.log('Provide a valid payload: either "open", or "close"');
      }
    },

    handleWindowPosition: function handleWindowPosition(payload) {
      var _that = $graffino,
          _this = this,
          vars = this.vars;

      switch (payload) {
        case 'store':
          vars.thisScrollPosition = _that.vars.$window.scrollTop();
          break;
        case 'restore':
          _that.vars.$window.scrollTop(vars.thisScrollPosition);
          break;
        default:
          _this.log('Provide a valid payload: either "store", or "restore"');
      }
    }
  }
});