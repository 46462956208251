'use strict';

/**
 * Name: Select2
 * Author: Graffino (http://www.graffino.com)
 * Plugin: https://github.com/select2/select2
 */

$.extend($graffino, {
  statusHandler: {
    name: 'status-handler',

    // Plugin options
    options: {
      autoInit: true,
      debug: false
    },

    // Scoped variables
    vars: {
      $status: $('.js-status'),
      $trigger: $('.js-status-trigger')
    },

    // Init method
    init: function init() {
      var _that = $graffino,
          _this = this,
          vars = this.vars;

      _this.log('Initialized.');

      if (_that.isOnPage(vars.$status) && _that.isOnPage(vars.$trigger)) {
        vars.$trigger.on('click', function (e) {
          e.preventDefault();
          vars.$status.toggleClass(_that.vars.stateClass.open);
        });
      } else {
        _this.log('No elements found in DOM.');
      }
    }
  }
});