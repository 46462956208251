'use strict';

/**
 * Name: Slick slider
 * Author: Graffino (http://www.graffino.com)
 */

$.extend($graffino, {
  slick: {
    name: 'slick',

    // Plugin options
    options: {
      autoInit: true,
      debug: false
    },

    // Scoped variables
    vars: {
      sliderClass: '.js-slick'
    },

    // Init method
    init: function init() {
      var _that = $graffino,
          _this = this,
          vars = this.vars;

      _this.log('Initialized.');

      // Check if element is in DOM
      if (_that.isOnPage($(vars.sliderClass))) {
        $(vars.sliderClass).each(function () {
          var $this = $(this),
              toggleBreakpoint = $this.attr('data-slick-toggle-breakpoint'),
              slickDefaults = {
            responsive: [{
              breakpoint: _that.vars.breakpoints.tablet,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            }, {
              breakpoint: _that.vars.breakpoints.small,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '40px'
              }
            }]
          },
              slickOptions = {
            dots: $this.attr('data-slick-dots') || false,
            arrows: $this.attr('data-slick-arrows') || false,
            slidesToShow: parseInt($this.attr('data-slick-show'), 10) || 6,
            slidesToScroll: $this.attr('data-slick-scroll') || 1,
            speed: $this.attr('data-slick-speed') || 1000,
            infinite: $this.attr('data-slick-infinite') || true,
            draggable: $this.attr('data-slick-draggable') || true,
            fade: $this.attr('data-slick-fade') || false,
            autoplay: $this.attr('data-slick-autoplay') || true,
            centerMode: $this.attr('data-slick-center') || false
          },
              timeoutID,
              currentSettings = $.extend(slickDefaults, slickOptions);

          toggleBreakpoint = toggleBreakpoint === undefined ? false : toggleBreakpoint;

          if (!toggleBreakpoint && !$this.hasClass(_that.vars.stateClass.slicked)) {
            $this.slick(currentSettings);
          }

          if (toggleBreakpoint !== false && _that.vars.breakpoints[toggleBreakpoint] !== undefined) {
            _that.resizeHandler.vars.onstop.push(function () {
              clearTimeout(timeoutID);
              var windowWidth = _that.vars.$window.width(),
                  breakpoint = _that.vars.breakpoints[toggleBreakpoint];
              if (windowWidth <= breakpoint && !$this.hasClass(_that.vars.stateClass.slicked)) {
                timeoutID = setTimeout(function () {
                  $this.slick(currentSettings);
                  _this.log('Slick has been [initialized] from the item at [' + toggleBreakpoint + '] breakpoint:');
                  if (_this.options.debug && _that.options.debug) {
                    console.log('\t', $this);
                  }
                }, 500);
              } else if (windowWidth > breakpoint && $this.hasClass(_that.vars.stateClass.slicked)) {
                $this.slick('unslick');
                _this.log('Slick has been [removed] on the item at [' + toggleBreakpoint + '] breakpoint:');
                if (_this.options.debug && _that.options.debug) {
                  console.log('\t', $this);
                }
              }
            }); // end push()
          } // end if

          setTimeout(function () {
            $this.on('breakpoint', function () {
              _this.log('Window has been resized by slick breakpoint event.');
              _that.vars.$window.trigger('resize');
            });
          }, 0);

          // Fire a window resize event
          _that.vars.$window.trigger('resize');
        });
      } else {
        _this.log('\t\u2514 Element(s) not found in DOM.');
      }
    }
  }
});