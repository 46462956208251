'use strict';

/**
 * Name: Links handler
 * Author: Graffino (http://www.graffino.com)
 */

$.extend($graffino, {
  passwordStrength: {
    name: 'password-strength',

    // Plugin options
    options: {
      autoInit: true,
      debug: true
    },

    // Scoped variables
    vars: {
      $steps: $('[data-step]'),
      currentStep: 1,
      $nextStep: $('.js-next-step'),
      $prevStep: $('.js-prev-step')
    },

    // Init method
    init: function init() {
      var _that = $graffino,
          _this = this,
          vars = this.vars;

      _this.log('Initialized.');
      if (_that.isOnPage(vars.$steps)) {
        _this.checkCurrent(vars.$steps);

        vars.$prevStep.on('click', function () {
          _this.callPrev();
        });

        vars.$nextStep.on('click', function () {
          _this.callNext();
        });
      } else {
        _this.log('\t\u2514 Element(s) not found in DOM.');
      }
    },
    checkCurrent: function checkCurrent(steps) {
      var _that = $graffino,
          vars = this.vars;

      steps.each(function () {
        if (parseInt($(this).attr('data-step'), 10) !== vars.currentStep) {
          steps.removeClass(_that.vars.stateClass.hidden);
          $(this).addClass(_that.vars.stateClass.hidden);
        }
      });
    },
    callNext: function callNext() {
      var vars = this.vars,
          _this = this;

      vars.currentStep++;
      _this.checkCurrent(vars.$steps);
    },
    callPrev: function callPrev() {
      var vars = this.vars,
          _this = this;

      vars.currentStep--;
      _this.checkCurrent(vars.$steps);
    }
  }
});