'use strict';

/**
 * Name: Popup
 * Author: Graffino (http://www.graffino.com)
 * Plugin: https://github.com/dimsemenov/Magnific-Popup
 */

$.extend($graffino, {
  matchHeight: {
    name: 'match-height',

    // Plugin options
    options: {
      autoInit: true,
      debug: true
    },

    // Scoped variables
    vars: {
      matchHeightClass: '.js-match-height',
      eventClass: '.event',
      options: {
        property: 'min-height'
      }
    },

    // Init method
    init: function init() {
      var _that = $graffino,
          _this = this,
          vars = this.vars;

      _this.log('Initialized');

      _that.vars.bus.$on('filters.mounted', function () {
        setTimeout(function () {
          $(vars.matchHeightClass).matchHeight(vars.options);
          $(vars.eventClass).matchHeight(vars.options);
        }, 500);
      });

      _that.vars.bus.$on('filters.updated', function () {
        setTimeout(function () {
          $(vars.matchHeightClass).matchHeight(vars.options);
          $(vars.eventClass).matchHeight(vars.options);
        }, 500);
      });

      // Check if element is in DOM
      // Initializing plugin for regular items (not Vue components)
      if (_that.isOnPage($(vars.matchHeightClass))) {
        $(vars.matchHeightClass).matchHeight(vars.options);
        // Add matchHeight plugin to resize handler with delay
        _that.resizeHandler.vars.onstop.push(function () {
          return setTimeout(function () {
            return $.fn.matchHeight._update();
          }, 500);
        });
        // Trigger window resize event
        setTimeout(function () {
          return _that.vars.$window.trigger('resize');
        }, 1000);
      } else {
        _this.log('\t\u2514 Element(s) not found in DOM.');
      }
    }
  }
});