'use strict';

/**
 * Name: Navigation
 * Author: Graffino (http://www.graffino.com)
 */

$.extend($graffino, {
  datepicker: {
    name: 'datepicker',

    // Plugin options
    options: {
      autoInit: true,
      debug: false
    },

    // Scoped variables
    vars: {
      curr: -1,
      prev: -1,
      datepickerClass: '.js-datepicker',
      clearClass: '.js-clear-datepicker',
      triggerClass: '.js-trigger-datepicker',
      $datepicker: undefined,
      datepickerInstance: undefined,
      $clear: undefined,
      $trigger: undefined,
      options: {}
    },

    // Init method
    init: function init() {
      var _that = $graffino,
          _this = this,
          vars = this.vars;

      _this.log('Initialized.');

      // Check if element is in DOM
      if (_that.isOnPage($(vars.datepickerClass))) {
        vars.options = {
          dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          dateFormat: 'dd-MM-yy',
          beforeShowDay: function beforeShowDay(date) {
            return [true, date.getTime() === vars.curr || date.getTime() === vars.prev ? 'ui-state-active' : date.getTime() >= Math.min(vars.prev, vars.curr) && date.getTime() <= Math.max(vars.prev, vars.curr) ? 'ui-selected-range' : ''];
          },
          onSelect: function onSelect(dateText, inst) {
            var d1, d2, p1, p2;
            vars.prev = vars.curr;
            vars.curr = new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay).getTime();
            if (vars.prev === -1 || vars.prev === vars.curr) {
              vars.prev = vars.curr;
              vars.$datepicker.val(dateText);
              d1 = dateText;
              p1 = $.datepicker.formatDate('yy-mm-dd', new Date(dateText), {});
              inst.inline = true;
            } else {
              d1 = $.datepicker.formatDate('dd-MM-yy', new Date(Math.min(vars.prev, vars.curr)), {});
              d2 = $.datepicker.formatDate('dd-MM-yy', new Date(Math.max(vars.prev, vars.curr)), {});
              // Parsable date for event filters
              p1 = $.datepicker.formatDate('yy-mm-dd', new Date(Math.min(vars.prev, vars.curr)), {});
              p2 = $.datepicker.formatDate('yy-mm-dd', new Date(Math.max(vars.prev, vars.curr)), {});
              vars.$datepicker.val(d1 + ' - ' + d2);
              inst.inline = false;
            }

            var $thisInput = $(this),
                $thisClear = $thisInput.parent().find(vars.clearClass),
                isFilled = $thisInput.val() !== '';

            if (isFilled) {
              $thisClear.removeClass(_that.vars.stateClass.hidden);
            }

            // Send data to Vue instance
            _that.vars.bus.$emit('datepicker.update', p1, p2);
          }
        };

        _that.vars.bus.$on('filters.mounted', function () {
          vars.$datepicker = $(vars.datepickerClass);
          vars.datepickerInstance = vars.$datepicker.datepicker(vars.options);
        });
      } else {
        _this.log('\t\u2514 Element(s) not found in DOM.');
      }

      if (_that.isOnPage($(vars.triggerClass))) {
        _that.vars.bus.$on('filters.mounted', function () {
          vars.$trigger = $(vars.triggerClass);
          vars.$trigger.on('click', function (e) {
            e.preventDefault();
            $(this).parent().find(vars.datepickerClass).datepicker('show');
          });
        });
      } else {
        _this.log('\t\u2514 Element(s) not found in DOM.');
      }

      if (_that.isOnPage($(vars.clearClass))) {
        _that.vars.bus.$on('filters.mounted', function () {
          vars.$clear = $(vars.clearClass);
          vars.$clear.on('click', function (e) {
            e.preventDefault();
            $(this).addClass(_that.vars.stateClass.hidden);
            var $targetDatepicker = $(this).parent().find(vars.datepickerClass);
            $targetDatepicker.datepicker('destroy');
            $targetDatepicker.val('');
            $targetDatepicker.datepicker(vars.options);
            vars.curr = -1;
            vars.prev = -1;
            // Send data to Vue instance
            _that.vars.bus.$emit('datepicker.update', '', '');
          });
        });
      } else {
        _this.log('\t\u2514 Element(s) not found in DOM.');
      }
    }
  }
});