'use strict';

/**
 * Name: Fonts handler
 * Author: Graffino (http://www.graffino.com)
 */

/* global location */

$.extend($graffino, {
  navigateToSection: {
    name: 'navigate-to-section',

    // Plugin options
    options: {
      autoInit: true,
      debug: false
    },

    // Scoped variables
    vars: {},

    // Init method
    init: function init() {
      var _this = this;

      _this.log('Initialized.');

      $('a[href*=#]:not(.js-popup-anchor)').on('click', function () {
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') || location.hostname === this.hostname) {
          var target = $(this.hash);
          target = target.length > 0 ? target : $('#' + this.hash.slice(1));
          if (target.length > 0) {
            $('html,body').animate({
              scrollTop: target.offset().top
            }, 1000);
            return false;
          }
        }
      });
    }
  }
});