'use strict';

/**
 * Name: Fonts handler
 * Author: Graffino (http://www.graffino.com)
 */

$.extend($graffino, {
  fontsHandler: {
    name: 'fonts-handler',

    // Plugin options
    options: {
      autoInit: true,
      debug: false
    },

    // Scoped variables
    vars: {
      // Check if webfonts are loaded. Type in main font-family name. Set to false to prevent detection.
      fontFaceObserverName: 'SourceSansPro'
    },

    // Init method
    init: function init() {
      var _that = $graffino,
          _this = this,
          vars = this.vars,
          observer;

      _this.log('Initialized. Waiting for fonts to load...');

      if (vars.fontFaceObserverName) {
        observer = new FontFaceObserver(vars.fontFaceObserverName);
        // Add fonts-class when fonts are loaded
        observer.load().then(function () {
          _this.log('Fonts loaded, replacing [no-fonts] class with [fonts] on the [html] object.');
          _that.vars.$html.removeClass('no-fonts').addClass('fonts');
        }, function () {
          _this.log('Fonts not available.');
        });
      } else {
        _this.log('Custom font-face name not specified, replacing [no-fonts] class with [fonts] on the [html] object.');
        _that.vars.$html.removeClass('no-fonts').addClass('fonts');
      }
    }
  }
});