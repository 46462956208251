'use strict';

/**
 * Name: Select2
 * Author: Graffino (http://www.graffino.com)
 * Plugin: https://github.com/select2/select2
 */

$.extend($graffino, {
  stickyFooter: {
    name: 'sticky-footer',

    // Plugin options
    options: {
      autoInit: true,
      debug: true
    },

    // Scoped variables
    vars: {
      stickyFooterContainerClass: '.js-sticky-footer-container',
      $stickyFooterContainer: undefined,
      stickyFooterClass: '.js-sticky-footer',
      $stickyFooter: undefined,
      barHeight: undefined
    },

    // Init method
    init: function init() {
      var _that = $graffino,
          _this = this,
          vars = this.vars;

      vars.$stickyFooterContainer = $(vars.stickyFooterContainerClass);
      vars.$stickyFooter = $(vars.stickyFooterClass);
      vars.barHeight = $('.js-sticky-footer').outerHeight();

      if (_that.isOnPage(vars.$stickyFooter) && _that.isOnPage(vars.$stickyFooterContainer)) {
        _this.toggleVisibility();

        _that.scrollHandler.vars.throttled.push(function () {
          _this.toggleVisibility();
        });

        _this.log('Initialized.');
      } else {
        _this.log('No elements found in DOM.');
      }
    },

    toggleVisibility: function toggleVisibility() {
      var _that = $graffino,
          vars = this.vars,
          triggerPoint = vars.$stickyFooterContainer[0].offsetTop + vars.barHeight;

      if (_that.vars.$window.scrollTop() >= triggerPoint) {
        vars.$stickyFooter.addClass(_that.vars.stateClass.sticky);
        vars.$stickyFooterContainer.css('height', vars.barHeight + 'px');
        _that.vars.$body.css('margin-bottom', vars.barHeight + 'px');
      } else {
        vars.$stickyFooter.removeClass(_that.vars.stateClass.sticky);
        _that.vars.$body.css('margin-bottom', 'initial');
        vars.$stickyFooterContainer.css('height', 'initial');
      }
    }
  }
});