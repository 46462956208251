<!-- JS Module: ModuleName -->
<div class="module">
  <h1 class="module__title">{{module.title}}</h1>
  <div class="module__content">
    <ul class="module__list">
      {% include "partials/partialName.njk" %}
    </ul>
  </div>
</div>
<!-- /JS Module: ModuleName -->
