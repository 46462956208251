'use strict';

/**
 * Name: Select2
 * Author: Graffino (http://www.graffino.com)
 * Plugin: https://github.com/select2/select2
 */

$.extend($graffino, {
  headerHandler: {
    name: 'header-handler',

    // Plugin options
    options: {
      autoInit: true,
      debug: false
    },

    // Scoped variables
    vars: {
      $header: $('.js-header'),
      lastScrollTop: 0,
      delta: 5,
      scrolled: undefined,
      navbarHeight: $('.js-header').outerHeight(),
      $milestoneOffset: $('.js-header-milestone').offset().top,
      $milestoneHeight: $('.js-header-milestone').outerHeight()
    },

    // Init method
    init: function init() {
      var _that = $graffino,
          _this = this,
          vars = this.vars;

      if (_that.isOnPage(vars.$header)) {
        _this.toggleVisibility();
        _this.toggleAppearance();
        _this.showHeaderOnHome();

        _that.scrollHandler.vars.throttled.push(function () {
          _this.toggleVisibility();
          _this.toggleAppearance();
          _this.showHeaderOnHome();
        });

        _this.log('Initialized.');
      } else {
        _this.log('No elements found in DOM.');
      }
    },

    toggleVisibility: function toggleVisibility() {
      var _that = $graffino,
          vars = this.vars,
          scrolled = _that.vars.$window.scrollTop();

      if (Math.abs(vars.lastScrollTop - scrolled) <= vars.delta) {
        return;
      }
      // Hide nav
      if (scrolled > vars.lastScrollTop && scrolled > vars.navbarHeight) {
        vars.$header.removeClass(_that.vars.stateClass.visible).addClass(_that.vars.stateClass.hidden);
        _that.search.handleResults('close');
        // Show nav
      } else if (scrolled + _that.vars.$window.height() < _that.vars.$document.height()) {
        vars.$header.removeClass(_that.vars.stateClass.hidden).addClass(_that.vars.stateClass.visible);
      }
      vars.lastScrollTop = scrolled;
    },

    toggleAppearance: function toggleAppearance() {
      var _that = $graffino,
          vars = this.vars;

      if (_that.vars.$window.scrollTop() <= vars.delta) {
        vars.$header.removeClass(_that.vars.stateClass.active);
        vars.$header.addClass(_that.vars.stateClass.inactive);
      } else {
        vars.$header.addClass(_that.vars.stateClass.active);
        vars.$header.removeClass(_that.vars.stateClass.inactive);
      }
    },

    showHeaderOnHome: function showHeaderOnHome() {
      var _that = $graffino,
          vars = this.vars,
          scrolled = _that.vars.$window.scrollTop(),
          milestone = vars.$milestoneOffset + vars.$milestoneHeight;

      if (_that.vars.$body.hasClass('home') && _that.vars.$body.hasClass('not-logged')) {
        if (scrolled > milestone) {
          vars.$header.removeClass(_that.vars.stateClass.invisible);
        } else {
          vars.$header.addClass(_that.vars.stateClass.invisible);
        }
      }
    }
  }
});