'use strict';

/**
 * Name: Popup
 * Author: Graffino (http://www.graffino.com)
 * Plugin: https://github.com/dimsemenov/Magnific-Popup
 */

$.extend($graffino, {
  popup: {
    name: 'popup',

    // Plugin options
    options: {
      autoInit: true,
      debug: false
    },

    // Scoped variables
    vars: {
      $popup: $('.js-popup'),
      $anchor: $('.js-popup-anchor'),
      $close: $('.js-popup-close'),
      $video: $('.js-video')
    },

    // Init method
    init: function init() {
      var _that = $graffino,
          _this = this,
          vars = this.vars;

      _this.log('Initialized');

      if (_that.isOnPage(vars.$video)) {
        vars.$video.magnificPopup({
          type: 'iframe'
        });
      }
      // Check if element is in DOM
      if (_that.isOnPage(vars.$popup) || _that.isOnPage(vars.$anchor)) {
        vars.$anchor.each(function () {
          var $el = $(this),
              ID = $el.attr('data-booking-id'),
              name = $el.attr('data-booking-name'),
              $inlineContainer = $($el.attr('href')),
              options = {
            type: $el.attr('data-popup-type'),
            midClick: true,
            closeOnBgClick: true,
            enableEscapeKey: true,
            mainClass: 'mfp-fade',
            closeBtnInside: true,
            closeMarkup: '',
            callbacks: {
              open: function open() {
                _that.vars.bus.$emit('popup.open', ID, name);
              }
            }
          };

          _that.vars.bus.$on('search.mounted', function () {
            $el = $('.js-search').find('.js-popup-anchor');
            $el.magnificPopup(options);
          });

          _that.vars.bus.$on('book.mounted', function () {
            $el = $('.js-popup-anchor').first();
            $el.magnificPopup(options);
          });

          if (_that.isOnPage($inlineContainer)) {
            $el.magnificPopup(options);
          } else {
            _this.log('\t\u2514 Inline content container not found in DOM (' + $el.attr('href') + ').');
          }
        });
      } else {
        _this.log('\t\u2514 Element(s) not found in DOM.');
      }

      if (_that.isOnPage(vars.$close)) {
        vars.$close.on('click', function (e) {
          e.preventDefault();
          $.magnificPopup.close();
        });
      }
    }
  }
});