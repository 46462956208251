'use strict';

/**
 * Name: Search
 * Author: Graffino (http://www.graffino.com)
 */

/* eslint no-undef: 0, no-unused-vars: 0, no-restricted-globals: 0 */

$.extend($graffino, {
  search: {
    name: 'search',

    // Plugin options
    options: {
      autoInit: true,
      debug: true
    },

    // Scoped variables
    vars: {
      searchComponent: '#search',
      resultsClass: '.js-search-results',
      searchClass: '.js-search',
      headerSearchClass: '.js-header-search',
      openSearchClass: 'js-open-search'
    },

    // Init method
    init: function init() {
      var _that = $graffino,
          _this = this,
          vars = this.vars;

      _this.log('Initialized.');

      _that.vars.$document.on('click', function (event) {
        if (!$(event.target).closest('.js-search').length > 0) {
          _this.handleResults('close');
        }
      });

      // Check if element is in DOM
      if (_that.isOnPage($(vars.searchComponent))) {
        var searchInstance = new Vue({
          el: vars.searchComponent,
          data: {
            query: '',
            results: []
          },
          mounted: function mounted() {
            this.$nextTick(function () {
              _that.vars.bus.$emit('search.mounted');
              // Listen for terms update and handleResults() accordingly
              _that.vars.bus.$on('search.results.update', function () {
                return _this.handleResults('open', event.target);
              });
            });
          },
          computed: {
            eventCollection: function eventCollection() {
              return this.results.events.data;
            },
            locationCollection: function locationCollection() {
              return this.results.locations.data;
            },
            regionCollection: function regionCollection() {
              return this.results.regions.data;
            }
          },
          methods: {
            openSearch: function openSearch() {
              // _that.navigation.closeNavigation();
              _this.handleResults('open', event.target);
            },
            closeSearch: function closeSearch() {
              _this.handleResults('close');
            }
          },
          watch: {
            query: function query() {
              _that.vars.bus.$emit('search.results.update', event.target);
              _this.handleResults('open', event.target);
            }
          },
          subscriptions: function subscriptions() {
            return {
              results: this.$watchAsObservable('query').pluck('newValue').filter(function (text) {
                return text.length > 2;
              }).throttle(250).distinctUntilChanged().switchMap(_that.search.fetchData).map(_that.search.formatResult)
            };
          }
        });
      } else {
        _this.log('\t\u2514 Element(s) not found in DOM.');
      }
    },
    fetchData: function fetchData(term) {
      return Rx.Observable.fromPromise($.ajax({
        url: '//' + location.host + '/events/match',
        method: 'POST',
        data: {
          q: term
        },
        headers: {
          'X-CSRF-TOKEN': window.Laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest'
        }
      }).promise());
    },
    formatResult: function formatResult(raw) {
      return raw.payload;
    },
    handleResults: function handleResults(payload, el) {
      var _that = $graffino,
          _this = this,
          vars = this.vars;

      switch (payload) {
        case 'close':
          if (_that.isOnPage($(vars.headerSearchClass))) {
            $(vars.headerSearchClass).removeClass('is-focused');
          }
          $(vars.resultsClass).removeClass(_that.vars.stateClass.visible).addClass(_that.vars.stateClass.hidden);
          $(vars.searchClass).removeClass(_that.vars.stateClass.open).addClass(_that.vars.stateClass.close);
          break;
        case 'open':
          if (_that.isOnPage($(vars.headerSearchClass))) {
            $(vars.headerSearchClass).addClass('is-focused');
          }
          $(el).parent().find(vars.resultsClass).removeClass(_that.vars.stateClass.hidden).addClass(_that.vars.stateClass.visible);
          if ($(el).hasClass(vars.openSearchClass)) {
            $(vars.searchClass).removeClass(_that.vars.stateClass.close).addClass(_that.vars.stateClass.open);
          }
          break;
        default:
          _this.log('Provide a valid case payload', payload);
      }
    }
  }
});