'use strict';

/**
 * Name: Links handler
 * Author: Graffino (http://www.graffino.com)
 */

$.extend($graffino, {
  passwordHandler: {
    name: 'password-handler',

    // Plugin options
    options: {
      autoInit: true,
      debug: true
    },

    // Scoped variables
    vars: {
      $passwordStrengthInput: $('.js-password-strength-input'),
      $passwordStrength: $('.js-password-strength'),
      $passwordStrengthText: $('.js-password-strength-text')
    },

    // Init method
    init: function init() {
      var _that = $graffino,
          _this = this,
          vars = this.vars;

      _this.log('Initialized.');
      if (_that.isOnPage(vars.$passwordStrengthInput) && _that.isOnPage(vars.$passwordStrengthText) && _that.isOnPage(vars.$passwordStrength)) {
        vars.$passwordStrengthInput.on('keypress keyup keydown', function () {
          var val = $(this).val();
          vars.$passwordStrengthText.text(_this.checkPassStrength(val));
          vars.$passwordStrength.attr('data-strength', _this.checkPassStrength(val));
        });
      } else {
        _this.log('\t\u2514 Element(s) not found in DOM.');
      }
    },

    scorePassword: function scorePassword(pass) {
      var score = 0;
      if (!pass) {
        return score;
      }

      // award every unique letter until 5 repetitions
      var letters = {};
      for (var i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
      }

      // bonus points for mixing it up
      var variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass)
      };

      var variationCount = 0;
      for (var check in variations) {
        if (variations[check]) {
          variationCount += variations[check] === true ? 1 : 0;
        }
      }
      score += (variationCount - 1) * 10;

      return parseInt(score, 10);
    },

    checkPassStrength: function checkPassStrength(pass) {
      var _this = this,
          score = _this.scorePassword(pass);

      if (score > 80) {
        return 'strong';
      }
      if (score > 60) {
        return 'good';
      }
      if (score >= 30) {
        return 'weak';
      }

      return '';
    }
  }
});